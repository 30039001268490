import {Controller} from '@hotwired/stimulus'
import debounce from 'debounce'

export default class extends Controller {
    initialize() {
        this.handleSubmit = debounce(this.handleSubmit, 300)
    }

    handleSubmit(event) {
        if (!event.target.reportValidity()) return

        event.target.form.requestSubmit()
    }
}
