import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['mapHover']

    initialize() {
        let mapNode = document.querySelector('map[name="map"]')
        mapNode.parentNode.insertBefore(this.mapHoverTarget, mapNode)
    }

    mouseOver(event) {
        document.querySelector('.map-state-name.' + event.currentTarget.dataset.state + ' a').classList.add('hover')
        this.mapHoverTarget.setAttribute('class', 'map-hover ' + event.currentTarget.dataset.state)
    }

    mouseOut() {
        let mapStateName = document.querySelectorAll('.map-state-name a')
        Array.prototype.forEach.call(mapStateName, function (mapStateName, _) {
            mapStateName.classList.remove('hover')
        })
        this.mapHoverTarget.setAttribute('class', 'map-hover')
    }
}
