import {Controller} from '@hotwired/stimulus'
import Awesomplete from 'awesomplete'

export default class extends Controller {
    static targets = ['searchInput', 'locationId']

    initialize() {
        this.awesome = new Awesomplete(this.searchInputTarget, {
            minChars: 1,
            maxItems: 10,
            filter: function (text, input) {
                // Our filter function always returns true because we already filter in the backend.
                // This also prevents bugs where we search for "sankt", return "st." and typeahead shows nothing
                // because the default filter does not match.
                return true
            }
        });

        let timeoutId;
        this.searchInputTarget.addEventListener('input', () => {
            this.setLocationIdValue('');
            if (timeoutId) clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                this.search();
            }, 250);
        });

        this.searchInputTarget.addEventListener('awesomplete-select', event => {
            event.preventDefault();
            this.setLocationIdValue(event.text.value);
            if (this.hasSearchInputTarget) this.searchInputTarget.value = event.text.label;
            if (this.autoSubmit()) this.element.submit();
            this.awesome.close();
        });
    }

    autoSubmit() {
        return this.data.get('no-submit') !== 'true';
    }

    search() {
        let inputValue = this.searchInputTarget.value || '';
        fetch(this.data.get('url') + '?q=' + encodeURIComponent(inputValue))
            .then(response => response.json())
            .then(names => {
                this.awesome.list = names;
                this.awesome.evaluate();
            })
    }

    setLocationIdValue(value) {
        if (!this.hasLocationIdTarget) return;
        if (this.locationIdTarget.value === value) return;

        this.locationIdTarget.value = value
        let event = document.createEvent('Event')
        event.initEvent('change', true, true)
        this.locationIdTarget.dispatchEvent(event)
    }
}
