import {Controller} from '@hotwired/stimulus'
import BugsnagLoader from '../components/bugsnag'

export default class extends Controller {
    connect() {
        if (!this.loadingAllowed()) return

        BugsnagLoader.getInstance()
    }

    loadingAllowed() {
        return document.body.dataset.cookieConsent === 'true' && (document.documentElement.dataset.env === 'production')
    }
}
