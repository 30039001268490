// Entry point for the build script in your package.json
import '@hotwired/turbo-rails'

import './controllers'
import './controllers/application/index'

import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/offcanvas'
import 'bootstrap/js/dist/tab'

import './components/font_awesome'
