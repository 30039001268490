import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    initialize() {
        this.dropdowns = this.element.querySelectorAll('.dropdown:not(.is-hoverable)')
    }

    connect() {
        Array.prototype.forEach.call(this.dropdowns, dropdown => dropdown.addEventListener('click', (event) =>
            this.onDropdownClick(event, dropdown)))
        document.addEventListener('click', this.onDocumentClick)
    }

    disconnect() {
        Array.prototype.forEach.call(this.dropdowns, dropdown =>
            dropdown.removeEventListener('click', this.onDropdownClick))
        document.removeEventListener('click', this.onDocumentClick)
    }

    onDropdownClick = (event, dropdown) => {
        event.stopPropagation()
        let dropdowns = [...this.dropdowns]
        dropdowns = dropdowns.filter(item => item !== dropdown)
        Array.prototype.forEach.call(dropdowns, element => element.classList.remove('is-active'))
        dropdown.classList.toggle('is-active')
    }

    onDocumentClick = (event) => {
        Array.prototype.forEach.call(this.dropdowns, dropdown => dropdown.classList.remove('is-active'))
    }
}
