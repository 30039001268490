import noUiSlider from 'nouislider'
import wNumb from 'wnumb'
import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['rangeSlider', 'minRangeInput', 'maxRangeInput', 'minRangeLabel', 'maxRangeLabel']

    connect() {
        noUiSlider.create(this.rangeSliderTarget, {
            // start: [0, 500_000],
            start: [this.minRangeInputTarget.value, this.maxRangeInputTarget.value],
            connect: true,
            step: 1000,
            range: {'min': 0, 'max': 500000}
        })
        this.registerUpdateEventListener()
        if (this.data.has('fireSearchFormId')) this.registerChangeEventListener()
    }

    disconnect() {
        if (!!this.rangeSliderTarget && !!this.rangeSliderTarget.noUiSlider)
            this.rangeSliderTarget.noUiSlider.destroy()
    }

    registerUpdateEventListener() {
        const currencyValueFormat = wNumb({decimals: 0, thousand: '.', suffix: ' €'})

        this.rangeSliderTarget.noUiSlider.on('update', (values, handle) => {
            let value = values[handle]
            let currencyValueFormatted = currencyValueFormat.to(parseInt(value))
            if (handle === 0) {
                this.minRangeInputTarget.value = value
                this.minRangeLabelTarget.innerHTML = currencyValueFormatted
            } else if (handle === 1) {
                this.maxRangeInputTarget.value = value
                if (value >= this.rangeSliderTarget.noUiSlider.options.range.max) {
                    this.maxRangeLabelTarget.innerHTML = '> ' + currencyValueFormatted
                } else {
                    this.maxRangeLabelTarget.innerHTML = currencyValueFormatted
                }
            }
        })
    }

    registerChangeEventListener() {
        this.rangeSliderTarget.noUiSlider.on('change', (_values, _handle) => {
            document.getElementById(this.data.get('fireSearchFormId')).requestSubmit()
        })
    }
}