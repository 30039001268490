import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['element']

    initialize() {
        this._elements = this.elementTargets
    }

    showAll(_event) {
        this._elements.forEach(element => {
            element.classList.remove('d-none')
        })
    }

    showAE(_event) {
        this._showElementsOf(['A', 'B', 'C', 'D', 'E'])
    }

    showFJ(_event) {
        this._showElementsOf(['F', 'G', 'H', 'I', 'J'])
    }

    showKO(_event) {
        this._showElementsOf(['K', 'L', 'M', 'N', 'O'])
    }

    showPT(_event) {
        this._showElementsOf(['P', 'Q', 'R', 'S', 'T'])
    }

    showUZ(_event) {
        this._showElementsOf(['U', 'V', 'X', 'Y', 'Z'])
    }

    _showElementsOf(alphabet_range) {
        this._elements.forEach(element => {
            element.classList.add('d-none')
            let sortName = element.hasAttribute('data-sort-name') ? element.dataset.sortName : element.querySelector('a').innerText
            if (alphabet_range.includes(sortName.charAt(0).toUpperCase())) {
                element.classList.remove('d-none')
            }
        })
    }
}
