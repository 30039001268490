import {library, dom} from '@fortawesome/fontawesome-svg-core'
import {faCopyright, faStar as farStar, faTrashCan} from '@fortawesome/free-regular-svg-icons'
import {
    faStar,
    faBell,
    faUser,
    faSignOutAlt,
    faEllipsisV,
    faChevronDown,
    faArrowTurnUp,
    faPlus
} from '@fortawesome/free-solid-svg-icons'

document.addEventListener('DOMContentLoaded', function (_event) {
    library.add(
        faCopyright, faStar, farStar, faBell, faUser, faSignOutAlt, faEllipsisV, faChevronDown, faArrowTurnUp, faPlus,
        faTrashCan
    )
    dom.watch()
})
