import {Controller} from '@hotwired/stimulus'

import loadGoogleMapsApi from 'load-google-maps-api'

export default class extends Controller {
    static values = {geoApiKey: String, lat: String, lng: String}

    connect() {
        this._initMap(this.element.id, this.latValue, this.lngValue)
    }

    _initMap(selector, marker_position_lat, marker_position_lng) {
        loadGoogleMapsApi({key: this.geoApiKeyValue}).then((googleMaps) => {
            let map = new googleMaps.Map(document.getElementById(selector), {
                zoom: 12
            })
            let marker = new googleMaps.Marker({
                position: new googleMaps.LatLng(marker_position_lat, marker_position_lng),
                map: map
            })
            map.setCenter(marker.getPosition())
        }).catch(function (error) {
            console.error(error)
        })
    }
}
