import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    connect() {
        // Data loaded from: https://mediafiles.botpress.cloud/7decabca-ea5f-4787-9a8c-cde378378407/webchat/config.js

        window.botpressWebChat.init({
            "composerPlaceholder": "Chatte mit ZVG AI",
            "botConversationDescription": "Frag mich zum Thema Zwangsversteigerung",
            "botId": "7decabca-ea5f-4787-9a8c-cde378378407",
            "hostUrl": "https://cdn.botpress.cloud/webchat/v1",
            "messagingUrl": "https://messaging.botpress.cloud",
            "clientId": "7decabca-ea5f-4787-9a8c-cde378378407",
            "webhookId": "4d75570f-5f3d-4f3b-80e6-e0bf81d0b0b8",
            "lazySocket": true,
            "themeName": "prism",
            "botName": "ZVG AI",
            "stylesheet": "https://webchat-styler-css.botpress.app/prod/a7fb3e71-7828-4fa1-965d-b42a24736a73/v96855/style.css",
            "frontendVersion": "v1",
            "useSessionStorage": true,
            "showBotInfoPage": true,
            "enableConversationDeletion": true,
            "theme": "prism",
            "themeColor": "#2563eb"
        })
    }
}
